import React from "react";
import SEO from "../components/seo";
import Main from "../components/main";

const RandomPage = () => (
  <>
    <SEO title="Random thoughts" keywords={[`random thoughts`]} />
    <Main title={"Random thoughts"} random />
  </>
);

export default RandomPage;
